var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ma-3" },
    [
      _c("Snackbar", { ref: "snackbar" }),
      _vm._m(0),
      _c(
        "div",
        [
          _c("UserEditDialog", {
            attrs: { mode: "new", item: _vm.emptyItem },
            on: { ok: _vm.save },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              items: _vm.users,
              headers: _vm.headers,
              "sort-by": "id",
              "sort-desc": "",
              "footer-props": {
                showFirstLastPage: true,
                showCurrentPage: true,
                firstIcon: "mdi-arrow-collapse-left",
                lastIcon: "mdi-arrow-collapse-right",
                prevIcon: "mdi-minus",
                nextIcon: "mdi-plus",

                "items-per-page-text": "Показать на странице",
                "items-per-page-all-text": "Все",
                "items-per-page-options": [50, 100, 200, -1],
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item.id",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.id))]
                },
              },
              {
                key: "item.username",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.username))]
                },
              },
              {
                key: "item.balance",
                fn: function ({ item }) {
                  return [
                    _vm._v(" " + _vm._s(_vm.toFixed2(item.balance)) + " USDT"),
                  ]
                },
              },
              {
                key: "item.payoutBalance",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.toFixed2(item.payoutBalance)) +
                        " " +
                        _vm._s(item.terminal)
                    ),
                  ]
                },
              },
              {
                key: "item.terminal",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.terminal))]
                },
              },
              {
                key: "item.status",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " + _vm._s(item.status ? "Активна" : "Выключена") + " "
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "indigo",
                        attrs: { dense: "", text: "" },
                        on: {
                          click: function ($event) {
                            return _vm.setActive(item, !item.status)
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.status ? "Откл" : "Вкл") + " ")]
                    ),
                  ]
                },
              },
              {
                key: "item.role",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.role))]
                },
              },
              {
                key: "item.rateFee",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.rateFee))]
                },
              },
              {
                key: "item.country",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.country))]
                },
              },
              {
                key: "item.actions",
                fn: function ({ item }) {
                  return [
                    _c("UserEditDialog", {
                      attrs: { tag: _vm.tag, mode: "edit", item: item },
                      on: { ok: _vm.save },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "Withdraws" }, [
      _c("h3", [_vm._v("Users admin page - Recrypto")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }