<template>
  <div class="ma-3">

    <Snackbar ref="snackbar"></Snackbar>


    <div class="Withdraws">
      <h3>Users admin page - Recrypto</h3>
    </div>

    <div>
      <UserEditDialog mode="new" v-bind:item="emptyItem" v-on:ok="save"></UserEditDialog>
    </div>

    <div>
      <v-data-table
          :items="users"
          :headers="headers"
          class="elevation-1"
          sort-by="id"
          sort-desc

          :footer-props="{
                showFirstLastPage: true,
                showCurrentPage:true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',

                'items-per-page-text':'Показать на странице',
                'items-per-page-all-text':'Все',
                'items-per-page-options':
[
  50,
  100,
  200,
  -1
]
          }"
      >

        <template v-slot:item.id="{ item }"> {{ item.id }}</template>
        <template v-slot:item.username="{ item }"> {{ item.username }}</template>
        <template v-slot:item.balance="{ item }"> {{ toFixed2(item.balance) }} USDT</template>
        <template v-slot:item.payoutBalance="{ item }"> {{ toFixed2(item.payoutBalance) }}  {{ item.terminal }}</template>
        <template v-slot:item.terminal="{ item }"> {{ item.terminal }}</template>
        <template v-slot:item.status="{ item }"> {{ item.status }}</template>
        <template v-slot:item.role="{ item }"> {{ item.role }}</template>
        <template v-slot:item.rateFee="{ item }"> {{ item.rateFee }}</template>

        <template v-slot:item.country="{ item }"> {{ item.country }}</template>


        <template v-slot:item.status="{ item }">
          {{ item.status ? "Активна" : "Выключена" }}
          <v-btn dense class="indigo" text @click="setActive(item, !item.status)">{{ item.status ? "Откл" : "Вкл" }}
          </v-btn>
        </template>


        <template v-slot:item.actions="{ item }">
          <UserEditDialog v-bind:tag="tag" mode="edit" v-bind:item="item" v-on:ok="save"></UserEditDialog>
        </template>



      </v-data-table>

    </div>
  </div>

</template>

<script>


import UserEditDialog from '../components/dialogs/UserEditDialog.vue'


import gql from "graphql-tag";
import {getHeaders} from "../store";

const RestApiUrl = process.env.VUE_APP_REST_API_URL_ADMIN + "/user"

import {fetcher} from "../store";
import Snackbar from "../components/controls/Snackbar.vue";

import  {toFixed2} from "../util/utils"

export default {
  name: 'Users',

  components: {UserEditDialog, Snackbar},

  apollo: {
    $subscribe: {
      users: {
        query: gql`subscription  {
                        users {
    callback
    cryptocurrency_selection_settings
    id
    login_email
    merchant_conversion_fee
    merchant_withdrawal_fee
    password
    role
    token
    username
                        }
                    }`,
        result({data}) {
          this.users = data.users;
        },
      },
    },
  },


  data: () => ({
    tag: 'test',

    users: [],

    emptyItem: {
      balance: "",
      comment: "",
      fee: "",
      id: "",
      password: "",
      role: "",
      status: "",
      terminal: "",
      token: "",
      username: "",
      rateFee:"",
      country:"",
      excludedChats:[]

    },

    headers: [
      {text: 'id', value: 'id'},
      {text: 'Имя пользователя', value: 'username'},
      //{text: 'Баланс', value: 'balance'},
      //{text: 'Баланс выплат', value: 'payoutBalance'},
      ///{text: 'Терминал', value: 'terminal'},
      {text: 'Статус', value: 'status'},
      {text: 'Роль', value: 'role'},
      //{text: 'Комиссия за обмен', value: 'rateFee'},
      //{text: 'Страна', value: 'country'},

      {text: 'Действия', value: 'actions'},
    ],
  }),

  methods: {
/*
    extractNumbersFromString: function (inputString) {
      // Используем регулярное выражение для поиска чисел в строке
      const numberRegex = /\d+/g;

      // Используем метод match() для нахождения всех чисел в строке
      const numbers = inputString.match(numberRegex);

      // Возвращаем массив чисел
      return numbers ? numbers.map(Number) : [];
    },*/


    dosnackbar(text) {
      this.$refs.snackbar.showit(text)
    },

    async setActive(item, active) {
      item.status = active
      //this.save(item, "edit")


      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify({status: active})
      };

      let url = `${RestApiUrl}/${item.id}`

      fetcher(this.dosnackbar, url, requestOptions)


    },

    async save(item, mode, orig) {

      let body = ""

      if (mode === 'edit') {
        let kne = this.compare2(item, orig)

        if (kne.length === 0){
          alert("не изменено")
          return
        }

        let changed = []

        for (let k in kne) {
          changed[kne[k]] = item[kne[k]]
        }

        body = JSON.stringify(Object.fromEntries(Object.entries(changed)))
      } else
        body = JSON.stringify(item)

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: mode === "new" ? 'POST' : 'PATCH',
        headers: headers,
        body: body
      };

      let url = `${RestApiUrl}/${mode === "new" ? "" : item.id}`

      fetcher(this.dosnackbar, url, requestOptions)

    },

    toFixed2(num) {
      return toFixed2(num);//num?.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
    },


    compare2(object1, object2) {
      const keys1 = Object.keys(object1);
      const keys2 = Object.keys(object2);
      if (keys1.length !== keys2.length) {
        return false;
      }

      let kne = []

      for (let key of keys1) {
        if ( JSON.stringify(object1[key]) !== JSON.stringify(object2[key]) ) {
          kne.push(key)
        }
      }

      return kne;

    }


  },

}
</script>
