<template>


  <v-dialog
      v-model="dialog"
      width="700"
  >
    <template v-slot:activator="{ on }">
      <v-btn class="indigo ma-3"
             text v-on="on">
        {{ mode==="new" ? "создание" : "правка"}}
      </v-btn>
    </template>

    <v-card>
      <v-card-title primary-title>юзер - {{ mode==="new" ? "создание" : "правка"}}</v-card-title>

      <div>

        <v-form>
          <v-container>
            <v-row>
              <v-col  cols="10" >

                <v-text-field  v-model="item2.username"   label="username"   outlined  clearable  ></v-text-field>
                <v-text-field  v-model="item2.password"   label="password"   outlined  clearable  ></v-text-field>

                <!--v-text-field  v-model="item2.terminal"   label="terminal"   outlined  clearable  ></v-text-field-->
                <v-text-field  v-model="item2.role"   label="role"   outlined  clearable  ></v-text-field>
                <!--v-text-field  v-model="item2.rateFee"   label="rateFee"   outlined  clearable  ></v-text-field>



                <v-text-field v-if="mode==='edit'" v-model="item2.token"   label="token"   outlined  clearable  ></v-text-field>

                <v-text-field  v-model="item2.comment"   label="comment"   outlined  clearable  ></v-text-field>

                <v-text-field v-if="mode==='edit'" v-model="item2.status"   label="status"   outlined  clearable  ></v-text-field>
                <v-text-field  v-model="item2.fee" type="number"   label="fee"   outlined  clearable  ></v-text-field>
                <v-text-field v-if="mode==='edit'" v-model="item2.balance"   label="balance"   outlined  clearable  ></v-text-field>

                <v-text-field  v-model="item2.country"   label="country"   outlined  clearable  ></v-text-field>


                <v-text-field--
                    v-model="item2.excludedChats"
                    label="Исключить чаты №,№,№"

                    outlined
                    clearable
                ></v-text-field-->

              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </div>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            color="primary"
            text
            @click="dialog = false"
        >
          Отмена
        </v-btn>

        <v-btn
            color="primary"
            text
            @click="ok"
        >
          Ок
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

module.exports =
    {
      data: function () {
        return {
          dialog: false,
          item2: {}
        }
      },

      props: ['item', 'mode'],

      mounted() {

        this.item2 = { ...this.item } //  clone obj  1 lvl

        //this.item2.excludedChats = this.item2.excludedChats.join(', ');

      },


      methods: {

      /* extractNumbersFromString: function (inputString) {
          // Используем регулярное выражение для поиска чисел в строке
          const numberRegex = /\d+/g;

          // Используем метод match() для нахождения всех чисел в строке
          const numbers = inputString.match(numberRegex);

          // Возвращаем массив чисел
          return numbers ? numbers.map(Number) : [];
        },*/



        ok: function () {
          this.dialog = false;

          //this.item2.excludedChats = this.extractNumbersFromString(this.item2.excludedChats)


          this.$emit('ok', this.item2, this.mode, this.item)
        }
      },


    }
</script>
